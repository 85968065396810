import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { createRoot, Root } from 'react-dom/client';
import React from 'react';
import {
  DashboardViewer,
  DashboardViewerProps,
} from '@odysseyenergy/dashboard-viewer';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { take } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'oes-dashboard-viewer',
  template: `<div #dashboardViewer></div>`,
})
export class DashboardViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dashboardViewer', { static: true })
  dashboardViewerRef!: ElementRef;

  private rootReactContainer!: Root;

  constructor(
    private _oauthService: OAuthService,
    private _userService: UserService,
  ) { }

  ngAfterViewInit() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.render(user.organization.id);
      });
  }

  ngOnDestroy() {
    if (this.rootReactContainer) {
      this.rootReactContainer.unmount();
    }
  }

  private render(orgId: string) {
    this.rootReactContainer = createRoot(this.dashboardViewerRef.nativeElement);
    const reactComponent = React.createElement(DashboardViewer, {
      orgId,
      // For now, location will be hardcoded to 'Home'. Further investigation is needed to make this dynamic.
      locationName: 'Home',
      baseUrl: environment.dashboardBuilderUrl,
      accessToken: this._oauthService.getAccessToken(),
    } as DashboardViewerProps);

    const parent = React.createElement('div', null, reactComponent);

    this.rootReactContainer.render(parent);
  }
}
