import { CiSiteRule } from './ci-site-rule.constant';
import { CookstoveRule } from './cookstove-rule.constant';
import { MeshgridRule } from './meshgrid-rule.constant';
import { MinigridRule } from './minigrid-rule.constant';
import { PueRule } from './pue-rule.constant';
import { ShsRule } from './shs-rule.constant';

export const PROJECT_SPECIFICATION_FORM_RULES = {
  MINIGRID: MinigridRule.rule(),
  MESHGRID: MeshgridRule.rule(),
  SOLAR_HOME_SYSTEM: ShsRule.rule(),
  PRODUCTIVE_USE_EQUIPMENT: PueRule.rule(),
  COOKSTOVE: CookstoveRule.rule(),
  CI_SITE: CiSiteRule.rule()
};
