!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.textMaskAddons = t() : e.textMaskAddons = t();
}(this, function () {
  return function (e) {
    function t(r) {
      if (n[r]) return n[r].exports;
      var i = n[r] = {
        exports: {},
        id: r,
        loaded: !1
      };
      return e[r].call(i.exports, i, i.exports, t), i.loaded = !0, i.exports;
    }
    var n = {};
    return t.m = e, t.c = n, t.p = "", t(0);
  }([function (e, t, n) {
    "use strict";

    function r(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var i = n(1);
    Object.defineProperty(t, "createAutoCorrectedDatePipe", {
      enumerable: !0,
      get: function () {
        return r(i).default;
      }
    });
    var o = n(2);
    Object.defineProperty(t, "createNumberMask", {
      enumerable: !0,
      get: function () {
        return r(o).default;
      }
    });
    var u = n(3);
    Object.defineProperty(t, "emailMask", {
      enumerable: !0,
      get: function () {
        return r(u).default;
      }
    });
  }, function (e, t) {
    "use strict";

    function n() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "mm dd yyyy",
        t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        n = t.minYear,
        o = void 0 === n ? 1 : n,
        u = t.maxYear,
        a = void 0 === u ? 9999 : u,
        c = e.split(/[^dmyHMS]+/).sort(function (e, t) {
          return i.indexOf(e) - i.indexOf(t);
        });
      return function (t) {
        var n = [],
          i = {
            dd: 31,
            mm: 12,
            yy: 99,
            yyyy: a,
            HH: 23,
            MM: 59,
            SS: 59
          },
          u = {
            dd: 1,
            mm: 1,
            yy: 0,
            yyyy: o,
            HH: 0,
            MM: 0,
            SS: 0
          },
          l = t.split("");
        c.forEach(function (t) {
          var r = e.indexOf(t),
            o = parseInt(i[t].toString().substr(0, 1), 10);
          parseInt(l[r], 10) > o && (l[r + 1] = l[r], l[r] = 0, n.push(r));
        });
        var s = 0,
          f = c.some(function (n) {
            var c = e.indexOf(n),
              l = n.length,
              f = t.substr(c, l).replace(/\D/g, ""),
              d = parseInt(f, 10);
            "mm" === n && (s = d || 0);
            var p = "dd" === n ? r[s] : i[n];
            if ("yyyy" === n && (1 !== o || 9999 !== a)) {
              var y = parseInt(i[n].toString().substring(0, f.length), 10),
                v = parseInt(u[n].toString().substring(0, f.length), 10);
              return d < v || d > y;
            }
            return d > p || f.length === l && d < u[n];
          });
        return !f && {
          value: l.join(""),
          indexesOfPipedChars: n
        };
      };
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = n;
    var r = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      i = ["yyyy", "yy", "mm", "dd", "HH", "MM", "SS"];
  }, function (e, t) {
    "use strict";

    function n(e) {
      if (Array.isArray(e)) {
        for (var t = 0, n = Array(e.length); t < e.length; t++) n[t] = e[t];
        return n;
      }
      return Array.from(e);
    }
    function r() {
      function e() {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : l,
          r = t.length;
        if (t === l || t[0] === m[0] && 1 === r) return m.split(l).concat([h]).concat(x.split(l));
        if (t === A && w) return m.split(l).concat(["0", A, h]).concat(x.split(l));
        var c = t[0] === d && D;
        c && (t = t.toString().substr(1));
        var s = t.lastIndexOf(A),
          f = s !== -1,
          b = void 0,
          O = void 0,
          M = void 0;
        if (t.slice(q * -1) === x && (t = t.slice(0, q * -1)), f && (w || H) ? (b = t.slice(t.slice(0, Y) === m ? Y : 0, s), O = t.slice(s + 1, r), O = i(O.replace(y, l))) : b = t.slice(0, Y) === m ? t.slice(Y) : t, L && ("undefined" == typeof L ? "undefined" : a(L)) === v) {
          var j = "." === _ ? "[.]" : "" + _,
            P = (b.match(new RegExp(j, "g")) || []).length;
          b = b.slice(0, L + P * B);
        }
        if (b = b.replace(y, l), R || (b = b.replace(/^0+(0$|[^0])/, "$1")), b = S ? o(b, _) : b, M = i(b), (f && w || H === !0) && (t[s - 1] !== A && M.push(g), M.push(A, g), O && (("undefined" == typeof I ? "undefined" : a(I)) === v && (O = O.slice(0, I)), M = M.concat(O)), H === !0 && t[s - 1] === A && M.push(h)), Y > 0 && (M = m.split(l).concat(M)), c && (M.length === Y && M.push(h), M = [p].concat(M)), x.length > 0 && (M = M.concat(x.split(l))), V && /[eE]/.test(t)) {
          var E = t.split(/[eE]/),
            C = u(E, 2),
            k = C[0],
            N = C[1],
            $ = k.match(/^[-+]?[0-9]*\.?[0-9]+/),
            T = N && N.match(/^[-+]?[0-9]+$/);
          if ($ && (!N || T)) {
            var Z = e($[0]),
              z = T ? i(T[0]) : [];
            return [].concat(n(Z), ["e"], n(z));
          }
        }
        return M;
      }
      var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        r = t.prefix,
        m = void 0 === r ? c : r,
        b = t.suffix,
        x = void 0 === b ? l : b,
        O = t.includeThousandsSeparator,
        S = void 0 === O || O,
        M = t.thousandsSeparatorSymbol,
        _ = void 0 === M ? s : M,
        j = t.allowDecimal,
        w = void 0 !== j && j,
        P = t.decimalSymbol,
        A = void 0 === P ? f : P,
        E = t.decimalLimit,
        I = void 0 === E ? 2 : E,
        C = t.requireDecimal,
        H = void 0 !== C && C,
        k = t.allowNegative,
        D = void 0 !== k && k,
        N = t.allowLeadingZeroes,
        R = void 0 !== N && N,
        $ = t.integerLimit,
        L = void 0 === $ ? null : $,
        T = t.allowScientificNotation,
        V = void 0 === T || T,
        Y = m && m.length || 0,
        q = x && x.length || 0,
        B = _ && _.length || 0;
      return e.instanceOf = "createNumberMask", e;
    }
    function i(e) {
      return e.split(l).map(function (e) {
        return h.test(e) ? h : e;
      });
    }
    function o(e, t) {
      return e.replace(/\B(?=(\d{3})+(?!\d))/g, t);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var u = function () {
        function e(e, t) {
          var n = [],
            r = !0,
            i = !1,
            o = void 0;
          try {
            for (var u, a = e[Symbol.iterator](); !(r = (u = a.next()).done) && (n.push(u.value), !t || n.length !== t); r = !0);
          } catch (e) {
            i = !0, o = e;
          } finally {
            try {
              !r && a.return && a.return();
            } finally {
              if (i) throw o;
            }
          }
          return n;
        }
        return function (t, n) {
          if (Array.isArray(t)) return t;
          if (Symbol.iterator in Object(t)) return e(t, n);
          throw new TypeError("Invalid attempt to destructure non-iterable instance");
        };
      }(),
      a = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      };
    t.default = r;
    var c = "$",
      l = "",
      s = ",",
      f = ".",
      d = "-",
      p = /-/,
      y = /\D+/g,
      v = "number",
      h = /\d/,
      g = "[]";
  }, function (e, t, n) {
    "use strict";

    function r(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    function i(e, t) {
      e = e.replace(O, y);
      var n = t.placeholderChar,
        r = t.currentCaretPosition,
        i = e.indexOf(v),
        s = e.lastIndexOf(p),
        f = s < i ? -1 : s,
        d = o(e, i + 1, v),
        h = o(e, f - 1, p),
        g = u(e, i, n),
        m = a(e, i, f, n),
        b = c(e, f, n, r);
      g = l(g), m = l(m), b = l(b, !0);
      var x = g.concat(d).concat(m).concat(h).concat(b);
      return x;
    }
    function o(e, t, n) {
      var r = [];
      return e[t] === n ? r.push(n) : r.push(h, n), r.push(h), r;
    }
    function u(e, t) {
      return t === -1 ? e : e.slice(0, t);
    }
    function a(e, t, n, r) {
      var i = y;
      return t !== -1 && (i = n === -1 ? e.slice(t + 1, e.length) : e.slice(t + 1, n)), i = i.replace(new RegExp("[\\s" + r + "]", m), y), i === v ? d : i.length < 1 ? g : i[i.length - 1] === p ? i.slice(0, i.length - 1) : i;
    }
    function c(e, t, n, r) {
      var i = y;
      return t !== -1 && (i = e.slice(t + 1, e.length)), i = i.replace(new RegExp("[\\s" + n + ".]", m), y), 0 === i.length ? e[t - 1] === p && r !== e.length ? d : y : i;
    }
    function l(e, t) {
      return e.split(y).map(function (e) {
        return e === g ? e : t ? x : b;
      });
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var s = n(4),
      f = r(s),
      d = "*",
      p = ".",
      y = "",
      v = "@",
      h = "[]",
      g = " ",
      m = "g",
      b = /[^\s]/,
      x = /[^.\s]/,
      O = /\s/g;
    t.default = {
      mask: i,
      pipe: f.default
    };
  }, function (e, t) {
    "use strict";

    function n(e, t) {
      var n = t.currentCaretPosition,
        o = t.rawValue,
        d = t.previousConformedValue,
        p = t.placeholderChar,
        y = e;
      y = r(y);
      var v = y.indexOf(a),
        h = null === o.match(new RegExp("[^@\\s." + p + "]"));
      if (h) return u;
      if (y.indexOf(l) !== -1 || v !== -1 && n !== v + 1 || o.indexOf(i) === -1 && d !== u && o.indexOf(c) !== -1) return !1;
      var g = y.indexOf(i),
        m = y.slice(g + 1, y.length);
      return (m.match(f) || s).length > 1 && y.substr(-1) === c && n !== o.length && (y = y.slice(0, y.length - 1)), y;
    }
    function r(e) {
      var t = 0;
      return e.replace(o, function () {
        return t++, 1 === t ? i : u;
      });
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = n;
    var i = "@",
      o = /@/g,
      u = "",
      a = "@.",
      c = ".",
      l = "..",
      s = [],
      f = /\./g;
  }]);
});