export const environment = {
  name: 'qa',
  version: require('../../build-number.json').buildNumber,
  production: true,
  logLevel: 'info',
  serverUri: 'https://qa-dt.odyssey.energy/',
  dataServerUri: 'https://qa-data.odyssey.energy',
  evalServerUri: 'https://qa-eval.odyssey.energy/',
  analytics: 'https://qa-analytics.odyssey.energy',
  apmServerUrl: 'https://oes-data-qa.apm.us-east-1.aws.found.io',
  openProjectUri: 'https://qa-tracker.odyssey.energy',
  oapHomeUrl: 'https://qa-oap.odyssey.energy',
  dashboardBuilderUrl: 'https://qa-dashboard-builder.odyssey.energy',
  cdnUri: 'https://d284j5xinimwah.cloudfront.net/project-group-images/',
  organizationCdnUri: 'https://d284j5xinimwah.cloudfront.net/organization-images',
  apiVersion: '1.0.0',
  hotKey: '5ab24-744be-fafb3-27431-98435',
  mixpanel: {
    projectId: '129819a4e8690d07af9a7028dab1b36c'
  },
  keycloak: {
    issuer: 'https://qa-auth.odyssey.energy/auth/realms/odyssey',
    url: 'https://qa-auth.odyssey.energy',
    realm: 'odyssey',
    theme: 'dt-ui'
  },
  firebase: {
    apiKey: 'AIzaSyD4Npfoi_kLB0dstKoT2iZxqK8ueuq-Kyg',
    authDomain: 'oes-dt-qa.firebaseapp.com',
    databaseURL: 'https://oes-dt-qa.firebaseio.com',
    firestoreFunctionsBaseUrl: 'https://us-central1-oes-dt.cloudfunctions.net',
    projectId: 'oes-dt-qa',
    storageBucket: 'oes-dt-qa.appspot.com',
    messagingSenderId: '790497869505'
  }
};
