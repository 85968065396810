import { Component, Input, OnInit } from '@angular/core';
import { isArray } from 'lodash-es';

@Component({
  selector: 'oes-formio-simple-form',
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.scss'],
  providers: []
})
export class FormioSimpleFormComponent implements OnInit {
  @Input() json: any;

  flatValues: any;

  constructor() {
  }

  ngOnInit() {
    const flattened = this.flattenForm(this.json);
    this.flatValues = this.cleanSaveButton(flattened);
  }

  private cleanSaveButton(form) {
    return {
      ...form,
      components: this.filterOutSaveButton(form.components)
    };
  }

  private filterOutSaveButton(components) {
    return components.filter(component => {
      if (component?.event !== 'save' ||
          component?.type !== 'button') {
        return true;
      }
      return false;
    });
  }

  private flattenForm(form) {
    return {
      ...form,
      components: this.flattenFormComponents(form.components)
    };
  }

  private flattenFormComponents(components) {
    if (!components) {
      return;
    }
    let flatComponents = [];

    components.forEach(component => {
      if (component.type === 'tabs') {
        component.components.forEach(tab => {
          flatComponents.push({
            type: 'htmlelement',
            tag: 'h2',
            className: 'simple-form-tab',
            content: tab.label,
            key: `heading-${tab.key}`
          });
          if (tab?.components) {
            flatComponents = flatComponents.concat(this.flattenFormComponents(tab.components));
          }
        });
      } else if (component.components) {
        component.components = this.flattenFormComponents(component.components);
        flatComponents.push(component);
      } else if (component.rows && isArray(component.rows)) {
        component.rows.forEach(row => {
          row.forEach(cell => {
            if (cell.components) {
              cell.components = this.flattenFormComponents(cell.components);
            }
          });
        });
        flatComponents.push(component);
      } else {
        if (component.type === 'textfield') {
          flatComponents.push({
            ...component,
            type: 'textarea',
            input: true,
            readOnly: true,
            rows: 5
          });
        } else {
          flatComponents.push(component);
        }
      }
    });

    return flatComponents;
  }
}
